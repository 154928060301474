<template>
  <div class="item" :span="24">
    <el-col v-if="productType=='FreeMailGoods'" class="itemBox">
      <div class="imgWrap" @click="jumpDetail(info)">
        <img :alt="info.name | defaultName" :src="info.pic?info.pic + picSuffix : defaultImage" />
      </div>
      <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
      <div class="priceBox">
        <span class="price">{{info.price  | capitalize}}</span>
        <!-- <del class="delPrice">{{info.originalPrice  | capitalize}}</del> -->
      </div>
      <p class="msg">热销中</p>
      <span class="AddBtn" @click="jumpDetail(info)">立即购买</span>
    </el-col>
  </div>
</template>
<style lang="less" scoped>
  @import "~style/index.less";

  .item {
    width: 200px;
    background: #fff;
    float: left;
    margin-top: 15px;
    margin-right: 15px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 10px;

    .itemBox {
      position: relative;

      .AddBtn {
        cursor: pointer;
        position: absolute;
        bottom: 16px;
        right: 0;
        width: 80px;
        text-align: center;
        padding: 5px 0;
        font-size: 14px;
        color: #fff;
        background: #FF3333;

      }
    }

    .imgWrap {
      padding: 15px 0;
      overflow: hidden;

      img {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          box-shadow: 10px 6px 6px -4px #dedada;
          border-radius: 7px;
        }
      }
    }

    .shopName {
      height: 22px;
      color: #333;
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // overflow:hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp:2;
      // -webkit-box-orient: vertical;
    }

    .priceBox {
      margin-top: 5px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      color: #999;

      // .labelBox {
      //     display: inline-block;
      //   background: #ff3333;
      //   padding: 0 5px;
      //   color: #fff;
      //   text-align: center;
      //   line-height:20px;
      //   position: relative;
      //   .arrow-right {
      //         position: absolute;
      //         right: -4px;
      //         width: 6px;
      //         height: 6px;
      //         top: 6px;
      //         background: #ff3333;
      //         border-top: 2px solid #ff3333;
      //         border-right: 2px solid #ff3333;
      //         transform: rotate(45deg);
      //   }
      // }
      .price {
        color: #ff3333;
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
      }

      .delPrice {
        padding-left: 10px;
        color: #929598;
        font-size: 12px;
      }
    }

    .msg {
      color: #929598;
      font-size: 12px;
      text-indent: 3px;
      line-height: 20px;
    }
  }
</style>

<script>
  /**
   * ?此组件目前仅限于小额包邮页面使用
   */
  import defaultImage from "@/assets/defaultImage.png";
  export default {
    name: "ItemComboLi",
    data() {
      return {
        picSuffix: localStorage.getItem("productPic"),
        currentIndex: 0,
        //价格框显示隐藏
        status: false,
        num: 0,
        // pics: 1,
        defaultImage: defaultImage,
        promotionMap: "",
      };
    },
    props: {
      info: {
        type: Object,
      },
      productType: {
        type: String,
      },
    },
    methods: {
      //跳转商品详情
      jumpDetail(info) {
        // console.log('info: ', info);
        this.$router.push({
          name: "FreeMailDetail",
          query: {
            id: info.id,
            promotionId: info.promotionId,
            isRecentExpiration: info.isRecentExpiration
          },
        });
      },
    },
    created() {},
    updated() {},
  };
</script>